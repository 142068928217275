//@import 'normalize';
//@import 'entypo';
@import 'breaker';
//@import 'mixins';
@import 'frontpage-buttons';

//// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 500px;
//$m: 800px;
//$l: 1050px;
//$xl: 1410px;
//
//// Default content settings.
//$contentWidth: 1030px;
//$contentPadding: 15px;
//$contentMaxWidth: 90%;
//
//// Font weights.
//$regular: 400;
//$medium: 500;
//$semibold: 600;
//$bold: 700;
//
//// Colors
//$blue: #00b5b9;
//$purple: #7c3e9a;
//$green: #30b900;
//$greenLight: #CCFFB9;
//$greenDark: #2ba600;
//$orange: #ff773d;
//$red: #e00438;
//$lightYellow: #fff3cc;
//$yellow: #fdc400;
//$lighterGray: #d6d2d0;
//$lightGray: #d6d2d0;
//$gray: #9a9492;
//$black: #24211f;
//$white: #fff;


@import "old-styling";

.alignright {
    margin: 1em 0 1em 1em;
    float: right;
}

.the-content {
    p {
        line-height: 1.6em
    }
}

.wp-block-embed {
    margin: 15px 0;
}

.top-menu {
    position: relative;
    margin: 14px .5em 9px;

    font-size: 15px;
    font-weight: bold;
    line-height: 17px;

    ul {
        display: flex;
        align-content: stretch;
        align-items: stretch;
        justify-content: center;

        li {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            height: 60px;

            a {
                color: #2e281f;
                text-decoration: none;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                position: relative;
            }
        }

        &.menu {
            z-index: 10;
            position: relative;

            > li {
                background: #ffffff;
                border-right: 1px solid #F5F4F1;

                &:first-of-type {
                    border-top-left-radius: .4em;
                    border-bottom-left-radius: .4em;
                }

                &:last-of-type {
                    border-top-right-radius: .4em;
                    border-bottom-right-radius: .4em;
                }

                &:last-of-type {
                    border-right: none;
                }

                &:hover {
                    ul {
                        z-index: 11;
                    }
                }

                &:hover.menu-item-has-children {
                    background: #e2e2e1;

                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;

                    ul {
                        display: flex;
                    }
                }

                &.menu-item-has-children,
                &.menu-item-has-children {
                    > a::after {
                        position: absolute;
                        bottom: 5px;
                        content: "⌄";
                        color: #c1bcb6;
                    }
                }

                &.current-menu-item,
                &.current-menu-parent {
                    background: #e2e2e1;

                    //&.menu-item-has-children {
                    //    padding-bottom: 40px;
                    //
                    //    ul {
                    //        display: flex;
                    //    }
                    //}
                }
            }
        }

        &.sub-menu {
            display: none;
            font-weight: normal;

            justify-content: center;

            background: #e2e2e1;
            border-bottom-left-radius: .4em;
            border-bottom-right-radius: .4em;

            > li {
                height: 80px;
                width: auto;

                a {
                    padding: 0 30px;
                }
            }

            position: absolute;
            top: 60px;
            left: 0;
            right: 0;
        }
    }
}

.menu-button {
    display: none;
}

article, .article-list li {
    max-width: 600px;
}

.font-bold {
    font-weight: 700;
}

.organization-search {
    font-size: 1em;
    padding: .4em;
    width: 280px;
}

.organization-content table {
    margin-bottom: 0px;
}

.status-icon {
    width: 24px;
    height: 24px;
    float: right;
}

.status-pre-approved, .status-pre-approved:hover {
    color: #e6ba00;
}

.status-approved, .status-approved:hover {
    color: #65a943;
}

.pre-approved-explanation {
    padding: 10px 10px 10px 10px;
    margin: 4px 0 0 0;
    background-color: #f7f7f7;
    border: 1px solid #eee;
    border-radius: .4em;
    color: #404040;
}

.donation-methods-section {
    padding: 2em 2em 2em 2em;
    margin: 2em 2em 0em 2em;
    background-color: #f7f7f7;
    border: 1px solid #eee;
    border-radius: .4em;
    color: #404040;
}

.donation-methods-content {
    h2 {
        margin-top: 0 !important;
        border-bottom: 0 !important;
    }
}

.tag-text {
    margin-right: 4px;
}

.tag-label {
    padding: 4px 6px 4px 6px;
    margin-right: 4px;
    margin-bottom: 4px;
    display: inline-block;
    background-color: #5e9c3e;
    border: 1px solid #eee;
    border-radius: .4em;
    color: #fff;
}

.document-hide {
    display: none;
}

.show-all-documents {
    text-decoration: underline;
    cursor: pointer;
}

.table-div {
    width: 100%;
    display: table;
    table-layout: fixed;
}

.tr-div {
    display: table-row;
}

.td-div {
    display: table-cell;
}

article {
    margin: 2em;

    img {
        max-width: 100%;
        height: auto;
    }

    .image {
        background: #eee;
        max-width: 100%;
        height: auto;

        img {
            display: block;
        }
    }

    h1 {
        margin-top: 0 !important;
    }

    .lead {
        font-weight: bold;
        font-size: 1.1em;
    }

    .footer {
        border-top: 1px solid #eee;
        clear: both;
        color: #a39176;
        margin: 1em 0 0 0;
        padding: 1em 0 0 0;
    }

    .share {
        margin: 1em 0 0 0;
    }
}

.article-list {
    padding: 0 !important;

    li {
        list-style: none !important;
        margin: 0 1em;

        &:not(:first-child) {
            padding-top: 1em;
        }

        &:not(:last-child) {
            padding-bottom: 1em;
            border-bottom: 1px solid #eee;
        }
    }

    a {
        padding: 0 1em;
        color: #6b5d48;
        text-decoration: none;
        display: block;
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        -ms-transition: none;
        transition: none;

        &:hover {
            color: #100e0a;

            .readmore {
                color: #0d4cad;
            }
        }
    }

    h1 {
        float: left;
        margin: 0 0 .3em 0 !important;
        border: 0 !important;
    }

    .image {
        //float: right;
        margin: 0 0 1em 0;

        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }

    .lead {
        clear: left;
    }

    .footer {
        zoom: 1;
        clear: both;
        color: #a39176;
        margin: 1em 0 0 0;

        &:before {
            content: "";
            display: table;
        }

        &:after {
            content: "";
            display: table;
            clear: both;
        }

        .readmore {
            color: #186dee;
            text-decoration: underline;
            float: left;
            font-weight: 700;
        }

        .timestamp {
            float: right;
        }
    }

    &.archive li {
        margin: 1em 0;

        &:not(:first-child) {
            padding-top: 0;
        }

        a {
            padding: 0;
        }

        h1 {
            font-size: 1.7em !important;
        }
    }
}

@include below(880px) {

    header {
        margin-bottom: 9px;
    }

    header .header-wrap {
        padding: 16px 18px;
        margin: .5em .5em 0;
        background: #fff;
        border-radius: .4em;
    }

    header h1 {
        justify-content: space-between
    }

    header h1 a {
        height: 60px;
        transition: none;
        margin: 0;
        padding-left: 80px;
        line-height: 60px;
    }

    header ul.language {
        position: relative;
        top: initial;
        right: initial;
    }

    .menu-button {
        width: 30px;
        padding: 28px 16px;
        display: block;
        border-top-right-radius: .4em;
        border-top-left-radius: .4em;
        margin-left: 20px;

        .hamburger {
            position: relative;
            width: 29px;
            height: 3px;
            background: #2e281f;

            &:before {
                content: "";
                width: 29px;
                height: 3px;
                background: #2e281f;
                position: absolute;
                bottom: 9px;
            }

            &:after {
                content: "";
                width: 29px;
                height: 3px;
                background: #2e281f;
                position: absolute;
                top: 9px;
            }
        }
    }

    .top-menu {
        display: none;
        margin-top: -.4em;
        border-top: 1px solid #F5F4F1;

        &.open {
            display: block;
        }

        * {
            box-sizing: border-box;
        }

        ul {
            flex-direction: column;
            border-bottom-left-radius: .4em;
            border-bottom-right-radius: .4em;
            overflow: hidden;
        }

        ul li {
            display: block;
            text-align: left;
            justify-content: left;
            height: auto;
            border-radius: 0 !important;
        }

        ul li ~ li {
            border-top: 1px solid #F5F4F1;
        }

        ul.menu > li.current-menu-item.menu-item-has-children {
            padding: 0;
        }

        ul.menu > li.menu-item-has-children > a::after {
            right: 16px;
            bottom: 50%;
            transform: translateY(50%);
        }

        ul.menu > li.current-menu-parent.menu-item-has-children {
            padding: 0;
        }

        ul li a {
            display: block;
            text-align: left;
            padding: 18px 16px;
            line-height: 1.5rem;
            width: 100%;
        }

        ul.sub-menu {
            position: relative;
            top: initial;
            left: initial;
            right: initial;
            border-top: 1px solid #d6d6d6;

            li {
                height: auto;
                display: block;
            }

            li ~ li {
                border-top: 1px solid #d6d6d6;
            }

            li a {
                padding: 18px 16px 18px 32px;
                line-height: 1.5rem;
                width: 100%;
            }
        }

    }
}

@include below(640px) {

    header h1 a {
        height: 40px;
        transition: none;
        margin: 0;
        padding-left: 80px;
        line-height: 60px;
    }

    header .header-wrap {
        padding: 0px;
    }

    .top-menu {
        margin-top: -4px;
    }
}