@import url("//fonts.googleapis.com/css?family=Roboto:400,700");

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    font-size: 100%;
    vertical-align: baseline
}

body {
    line-height: 1;
    color: #000;
    background: #fff
}

ol, ul {
    list-style: none
}

table {
    border-collapse: separate;
    border-spacing: 0;
    vertical-align: middle
}

caption, th, td {
    text-align: left;
    font-weight: normal;
    vertical-align: middle
}

a img {
    border: none
}

p {
    margin: 1em 0
}

strong, b {
    font-weight: 700
}

em, i {
    font-style: italic
}

a {
    color: #186dee;
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
    -o-transition: all 0.1s ease-out;
    -ms-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
}

a:hover {
    color: #5f9af3
}

a.plain {
    color: #2e281f;
    text-decoration: none;
}

a.plain:hover {
    color: #6b5d48;
    text-decoration: underline
}

section {
    margin: 0 2em;
}

@media (max-width: 55em) {
    section {
        margin: 0 1em;
        overflow: auto
    }
}

hr {
    height: 0;
    border: 0;
    border-top: 1px solid #c2b6a4;
    margin: 2em 0;
    clear: both
}

.tabs-nav {
    margin: 1em 0;
}

.tabs-nav ul {
    padding: 0 2em !important;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@media (max-width: 40em) {
    .tabs-nav ul {
        padding: 0 1em !important
    }
}

.tabs-nav ul li {
    list-style: none !important;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 0;
    border-color: #c2b6a4;
    border-width: 1px;
    border-style: none none solid none;
    padding: .5em 1em .5em 2.4em;
    cursor: pointer;
    background: #fff no-repeat .5em 50%;
}

.tabs-nav ul li.tab-selected {
    border-style: solid solid none solid;
    font-weight: 700
}

.tabs-nav ul li[data-tab='accounts'] {
    background-image: url("../img/icons/account_balance.svg")
}

.tabs-nav ul li[data-tab='key-numbers'] {
    background-image: url("../img/icons/chart.svg")
}

html, body {
    font: 400 100%/1.5 'Roboto', sans-serif;
    background: url("../img/background.png");
    color: #2e281f
}

#wrap {
    font-size: 1em;
    max-width: 70em;
    margin: 0 auto
}

header {
    position: relative;
}

header .header-wrap {
    display: flex;
    align-items: center;
    padding: 1em 2em;
    margin: .5em 0
}

@media (max-width: 40em) {
    header .header-wrap {
        padding: 0
    }
}

header h1 {
    position: relative;
    white-space: nowrap;
    flex: 1;
    display: flex;
    justify-content: center;
}

header h1 a {
    display: inline-block;
    background: url("../img/logo.svg") no-repeat 0 50%;
    height: 80px;
    background-size: contain;
    line-height: 80px;
    text-decoration: none;
    margin-top: 5px;
    padding-left: 113px;
    margin-right: 45px;
}

header h1 a .title {
    display: none
}

@media (max-width: 40em) {
    header h1 a {
        display: block;
        margin: 0;
        background-position: 50% 50%
    }
}

header h1 .slogan {
    display: inline-block;
    font-size: 21px;
    color: #2e281f !important;
    padding-left: 10px;
}

@media (max-width: 40em) {
    header h1 .slogan {
        display: none
    }
}

header ul.language {
    position: absolute;
    right: 52px;
    top: 55px;
    font-size: 16px;
}

header ul.language li {
    display: inline-block;
    margin: 0 0 0 1em
}

header ul.language a {
    text-decoration: none;
    font-size: .9em;
    line-height: 2.8em;
    text-transform: uppercase;
    color: #89775d;
}

header ul.language a:hover {
    color: #2e281f
}

@media (max-width: 40em) {
    header ul.language {
        display: none
    }
}

nav#main-menu {
    border-top-left-radius: .4em;
    border-top-right-radius: .4em;
    font-weight: 400;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    margin: 0 .5em .5em .5em;
}

@media (max-width: 55em) {
    nav#main-menu {
        padding: 0 .5em;
        margin: 0;
        font-size: 1em
    }

    nav#main-menu a {
        -webkit-border-radius: 0 !important;
        border-radius: 0 !important
    }
}

nav#main-menu ul.main {
    font-weight: 700;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@media (max-width: 55em) {
    nav#main-menu ul.main {
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -o-box-orient: vertical;
        -webkit-box-lines: single;
        -moz-box-lines: single;
        -o-box-lines: single;
        -webkit-flex-flow: column nowrap;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap
    }
}

nav#main-menu li {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    width: 0;
    white-space: nowrap;
    list-style: none !important;
    margin: 0;
    -webkit-box-shadow: 0 3px 5px rgba(46, 40, 31, 0.08);
    box-shadow: 0 3px 5px rgba(46, 40, 31, 0.08);
}

nav#main-menu li:not(:last-child) {
    margin-right: .5em
}

@media (max-width: 55em) {
    nav#main-menu li {
        width: auto;
        margin-right: 0 !important
    }

    nav#main-menu li:not(:first-child) {
        margin: .5em 0 0 0
    }
}

nav#main-menu li a {
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
    background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
    background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
    background-color: #ed1c24;
    padding: 0 .5em;
    text-decoration: none;
    color: #fff !important;
    display: block;
    line-height: 2.4em;
    text-align: center;
    font-size: 1.1em;
}

nav#main-menu li a::before {
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
    -o-transition: all 0.1s ease-out;
    -ms-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out
}

nav#main-menu li a:hover {
    background-color: #c61017;
}

nav#main-menu li a:hover::before {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
}

@media (max-width: 55em) {
    nav#main-menu li a {
        text-align: left
    }
}

nav#main-menu li.approved a {
    background-color: #6a4;
}

nav#main-menu li.approved a:hover {
    background-color: #508635 !important
}

nav#main-menu li.approved a::before {
    background: no-repeat url("../img/logo-emblem-white.svg") 50% 50%;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
    vertical-align: -.2em;
    margin: 0 .4em 0 0;
    width: 1.3em;
    height: 1.3em;
    display: inline-block;
    content: ''
}

nav#main-menu li.obs a::before {
    background: no-repeat url("../img/warning.svg") 50% 50%;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
    vertical-align: -.25em;
    margin: 0 .4em 0 0;
    width: 1.3em;
    height: 1.3em;
    display: inline-block;
    content: ''
}

nav#main-menu li:first-child a {
    border-top-left-radius: .4em
}

nav#main-menu li:last-child a {
    border-top-right-radius: .4em
}

#contents {
    border-bottom-left-radius: .4em;
    border-bottom-right-radius: .4em;
    background: #fff;
    -webkit-box-shadow: 0 10px 40px rgba(46, 40, 31, 0.08), 0 3px 5px rgba(46, 40, 31, 0.08);
    box-shadow: 0 10px 40px rgba(46, 40, 31, 0.08), 0 3px 5px rgba(46, 40, 31, 0.08);
    padding: 2em 0;
    margin: 0 .5em;
}

@media (max-width: 55em) {
    #contents {
        padding: 1em 0
    }
}

#contents > :first-child {
    padding-top: 0;
    margin-top: 0
}

#contents > :last-child {
    padding-bottom: 0;
    margin-bottom: 0
}

#contents h1 {
    font-size: 2em;
    margin: 1em 0 .5em 0;
    border-bottom: 1px solid #eee
}

#contents h2 {
    font-size: 1.56em;
    margin: 1em 0 .5em 0;
    border-bottom: 1px solid #eee
}

#contents h3 {
    font-size: 1.25em;
    margin: 1em 0 .5em 0;
}

#contents h4 {
    font-size: 1em;
    font-weight: bold;
    margin: 1em 0 .5em 0;
}

#contents > h1, #contents > h2 {
    margin: 1em 1em 0 1em;
}

@media (max-width: 55em) {
    #contents > h1, #contents > h2 {
        margin: .5em .5em 0 .5em
    }
}

#contents ul {
    padding: 0 0 0 2em;
}

#contents ul li {
    list-style: square outside
}

footer {
    text-align: center;
    font-size: .8em;
    color: #8c8c8d;
    margin: 2em 0;
}

footer p {
    margin: 0
}

footer a {
    color: #a6a6a6;
}

footer a:hover {
    color: #737373
}

.btn {
    text-decoration: none;
    background: -webkit-linear-gradient(top, #eee, #ddd);
    background: -moz-linear-gradient(top, #eee, #ddd);
    background: -o-linear-gradient(top, #eee, #ddd);
    background: -ms-linear-gradient(top, #eee, #ddd);
    background: linear-gradient(to bottom, #eee, #ddd);
    color: #2e281f
}

.frontpage-box {
    background-color: #736f68;
    background: url("../img/frontpage-bg.jpg") no-repeat 50% 50%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    color: #fff;
    -webkit-box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.4), 0 3px 5px rgba(46, 40, 31, 0.03), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.4), 0 3px 5px rgba(46, 40, 31, 0.03), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: center;
    padding: 4em 2em;
    //height: $box-height;
    margin: 0;
    border-top-left-radius: .4em;
    border-top-right-radius: .4em;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-box-lines: single;
    -moz-box-lines: single;
    -o-box-lines: single;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    margin: 0 .5em;
}

@media (max-width: 40em) {
    .frontpage-box {
        background: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 1em 0;
        -webkit-border-radius: 0;
        border-radius: 0
    }
}

.frontpage-box .search {
    font-size: 1.3em;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4), inset 0 1px 0 rgba(0, 0, 0, 0.6);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4), inset 0 1px 0 rgba(0, 0, 0, 0.6);
    -webkit-border-radius: .2em;
    border-radius: .2em;
    background: #fff;
    padding: .4em 0;
    text-align: left;
    max-width: 35em;
    margin: 0 auto;
}

@media (max-width: 40em) {
    .frontpage-box .search {
        font-size: 1.1em;
        -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), inset 0 1px 0 rgba(0, 0, 0, 0.3);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), inset 0 1px 0 rgba(0, 0, 0, 0.3)
    }
}

.frontpage-box .search::before {
    color: #ed1c24;
    display: block;
    font: 1.2em/1 FontAwesome;
    content: '\f002';
    margin: 0 0 0 .5em
}

.frontpage-box .search input {
    background: transparent;
    display: block;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font: 1.1em 'Roboto', sans-serif;
    padding: 0 .5em;
    border: 0;
}

.frontpage-box .search input[placeholder=''] {
    display: none
}

.frontpage-box .search input:focus {
    outline: none
}

.frontpage-box .search .rotation {
    display: block;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font: 1.1em 'Roboto', sans-serif;
    padding: 0 .5em;
    cursor: text;
    display: none;
    font-weight: 400;
    color: #999;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap
}

.frontpage-box .search ul.results {
    background: #fff;
    position: absolute;
    text-align: left;
    width: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    line-height: 1.8;
    max-height: 18em;
    border-bottom-left-radius: .2em;
    border-bottom-right-radius: .2em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 .2em .5em rgba(0, 0, 0, 0.3);
    box-shadow: 0 .2em .5em rgba(0, 0, 0, 0.3);
    border-top: 1px solid #aaa;
    font-weight: 400;
    top: 2em;
    overflow: auto;
    z-index: 10;
    display: none;
}

.frontpage-box .search ul.results.visible {
    display: block
}

@media (max-width: 40em) {
    .frontpage-box .search ul.results {
        max-height: 9em
    }
}

.frontpage-box .search ul.results li {
    cursor: pointer;
}

.frontpage-box .search ul.results li a {
    padding: 0 .6em;
    display: block;
    text-decoration: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
    -webkit-border-radius: .2em;
    border-radius: .2em;
    position: relative;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
}

.frontpage-box .search ul.results li a:hover {
    background: #eee;
    color: #0b4295
}

.frontpage-box .search ul.results li a .nb {
    position: absolute;
    top: .25em;
    right: .25em;
    background: #ed1c24;
    color: #fff;
    padding: 0 .5em;
    font-size: .8em;
    font-weight: 700;
    -webkit-border-radius: .2em;
    border-radius: .2em
}

.frontpage-box .search ul.results:empty::after {
    content: 'Ingen treff i innsamlingsregisteret.';
    color: #999;
    padding: 0 .6em;
    font-style: italic;
    line-height: 2
}

#intro {
    color: #2e281f;
    background: #fff;
    font-size: 1.1em;
    padding: 1em;
    text-align: center;
    -webkit-box-shadow: 0 3px 5px rgba(46, 40, 31, 0.03);
    box-shadow: 0 3px 5px rgba(46, 40, 31, 0.03);
    position: relative;
    z-index: 2;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    margin: 0 .5em .5em .5em;
    overflow: hidden;
}

#intro p {
    margin: 0
}

@media (max-width: 40em) {
    #intro {
        display: none
    }
}

.frontpage #main-menu li a {
    line-height: 3em;
    font-size: 1.1em;
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
    margin-top: .5em
}

.frontpage nav#external-links {
    margin: .5em;
}

.frontpage nav#external-links h2 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    margin: 1em 0 .5em 0;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.frontpage nav#external-links h2::before, .frontpage nav#external-links h2::after {
    display: block;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    height: 1px;
    background: #2e281f;
    content: ''
}

.frontpage nav#external-links h2::before {
    margin: 0 1em 0 0
}

.frontpage nav#external-links h2::after {
    margin: 0 0 0 1em
}

.frontpage nav#external-links ul {
    margin-top: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.frontpage .external-links-container {
    margin: 0 auto;
    width: 50%;
}

@media (max-width: 55em) {
    .frontpage nav#external-links ul {
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -o-box-orient: vertical;
        -webkit-box-lines: single;
        -moz-box-lines: single;
        -o-box-lines: single;
        -webkit-flex-flow: column nowrap;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap
    }
}

.frontpage nav#external-links li {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    width: 0;
    list-style: none;
}

@media (max-width: 55em) {
    .frontpage nav#external-links li {
        width: auto
    }
}

.frontpage nav#external-links li a {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    font-size: 1.1em;
    color: #2a2723;
    line-height: 1.1;
    text-decoration: none;
    text-align: center;
}

.frontpage nav#external-links li a i {
    margin: 0 .5em 0 0
}

@media (max-width: 55em) {
    .frontpage nav#external-links li a {
        padding: .5em
    }
}

.frontpage aside {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 2em;
    padding-top: 1em;
    margin-top: 1em;
}

@media (max-width: 40em) {
    .frontpage aside {
        display: block;
        width: auto
    }
}

.frontpage aside .about, .frontpage aside .twitterbox, .frontpage aside .likebox {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    width: 0
}

.frontpage aside .about {
    -webkit-box-flex: 2;
    -moz-box-flex: 2;
    -o-box-flex: 2;
    box-flex: 2;
    -webkit-flex: 2 0 auto;
    -ms-flex: 2 0 auto;
    flex: 2 0 auto
}

.frontpage aside .about p {
    margin: 0;
    padding: 0;
}

.frontpage aside .about p a {
    background: #e0dad2;
    display: block;
    margin: 0 0 1em 0;
    padding: .5em 1em;
    text-decoration: none;
    color: #2e281f;
    border: 1px solid #a39176;
    -webkit-box-shadow: inset 0 1px 0 #fff;
    box-shadow: inset 0 1px 0 #fff;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
    position: relative;
}

.frontpage aside .about p a:hover {
    background: #ccc2b3
}

.frontpage aside .about p a:active {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
    background: #ccc2b3;
    -webkit-box-shadow: inset 0 -1px 0 #fff;
    box-shadow: inset 0 -1px 0 #fff;
    top: 1px
}

.frontpage aside .twitterbox {
    margin-right: 1em;
}

.frontpage aside .twitterbox iframe {
    width: 100% !important;
    height: 14em !important
}

.frontpage aside .likebox {
    margin-right: 1em;
}

.frontpage aside .likebox .fb_iframe_widget, .frontpage aside .likebox .fb_iframe_widget span, .frontpage aside .likebox .fb_iframe_widget span iframe[style] {
    width: 100% !important;
    height: 14em !important
}

.org #contents h1 {
    margin-bottom: .5em
}

.org .column-main {
    width: 70%;
    display: inline-block;
    vertical-align: top;
}

@media (max-width: 55em) {
    .org .column-main {
        display: block;
        width: auto
    }
}

.org .column-main .warning {
    background: #fcb;
    color: #c61017;
    border-left: 5px solid #c61017;
    padding: 1em 2em;
}

.org .column-main .warning a {
    color: #c61017;
}

.org .column-main .warning a:hover {
    color: #970c12
}

.org .column-main .information {
    margin: 0 2em;
}

@media (max-width: 55em) {
    .org .column-main .information {
        margin: 0 1em
    }
}

.org .column-main .extra-info {
    display: none
}

.org .column-main .annual-returns {
    background: #f5f3f0;
    padding: .5em 1em;
    margin-top: 1em;
}

.org .column-main .annual-returns h2 {
    border: none !important;
    margin: 0 !important;
    font-size: 1.1em !important;
    font-weight: 700 !important
}

.org .column-main .annual-returns p {
    margin: .5em 0
}

.org aside {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 2em 0 0;
}

@media (max-width: 55em) {
    .org aside {
        display: block;
        width: auto;
        padding: 0 1em
    }
}

.org aside ul {
    padding: 0 !important;
}

.org aside ul li {
    list-style: none !important
}

.org aside .logo img {
    width: 100%
}

.org aside address {
    margin: 1em 0;
}

.org aside address .name {
    font-weight: 700
}

.org aside address .phone, .org aside address .email, .org aside address .org {
    margin: .5em 0 0 0;
    padding: .5em 0 0 0;
    border-top: 1px solid #eee
}

.org aside .extras {
    font-weight: 400
}

.org aside .documents li i {
    color: #a39176;
    margin: 0 .5em 0 0
}

.org td, .org th {
    padding: 0 .5em
}

.org td {
    font-weight: 400;
    text-align: right;
    width: 5em;
}

@media (max-width: 55em) {
    .org td {
        width: auto
    }
}

.org td:nth-child(1) {
    text-align: left;
    width: auto
}

.org td .description {
    position: relative;
    float: right;
}

@media (max-width: 55em) {
    .org td .description {
        display: none
    }
}

.org td .description .label {
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    text-align: center;
    background: #ccc2b3;
    color: #fff;
    -webkit-border-radius: .75em;
    border-radius: .75em;
    cursor: help
}

.org td .description .text {
    display: none;
    font-weight: 400;
    position: absolute;
    width: 20em;
    right: -22.5em;
    top: 0;
    background: #fdfdfc;
    z-index: 100;
    padding: 0 1em;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3)
}

.org td .description:hover .text {
    display: block
}

.org th {
    font-weight: 700;
    font-size: 1.1em;
    text-align: right;
    border-bottom: 1px solid #ccc2b3;
    background: #f5f3f0;
}

.org th:nth-child(1) {
    text-align: left
}

.org th .description {
    position: relative;
    display: inline-block;
    font-size: .9em;
    font-weight: 400;
    text-align: left;
}

.org th .description .label {
    font-size: .8em;
    color: #a39176;
    cursor: help;
    text-decoration: underline
}

.org th .description .text {
    display: none;
    position: absolute;
    width: 20em;
    right: -22.5em;
    top: 0;
    background: #fdfdfc;
    z-index: 100;
    padding: 0 1em;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3)
}

.org th .description:hover .text {
    display: block
}

.org table {
    width: 100%;
    margin: 0 0 2em 0;
    line-height: 1.7em;
}

.org table .details {
    text-align: right;
    margin: 0 0 0 1em;
    font-weight: 400;
    font-size: .8em;
    color: #a39176
}

.org table .sum td {
    font-weight: 400;
    border-top: 1px solid #ccc2b3;
    border-bottom: 3px double #ccc2b3
}

.org table .total td {
    font-weight: 400
}

.org .key-numbers ul {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0 !important;
}

@media (max-width: 40em) {
    .org .key-numbers ul {
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -o-box-orient: vertical;
        -webkit-box-lines: single;
        -moz-box-lines: single;
        -o-box-lines: single;
        -webkit-flex-flow: column nowrap;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap
    }
}

.org .key-numbers li {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    width: 0;
    padding: .5em;
    font-weight: 400;
    margin: 0;
    padding: 0;
    list-style: none !important;
    text-align: center;
}

@media (max-width: 40em) {
    .org .key-numbers li {
        width: auto
    }
}

.org .key-numbers li.number {
    text-align: right
}

.org .key-numbers li h2 {
    margin-top: 0 !important;
    font-size: 1.1em !important;
    font-weight: 600
}

.org .key-numbers .container {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 110px;
    height: 110px;
}

.org .key-numbers .container .label {
    position: absolute;
    width: 55px;
    height: 55px;
    line-height: 55px;
    top: 27.5px;
    left: 27.5px;
    font-weight: 700;
    text-align: center;
}

.org .key-numbers .container .label:after {
    content: '%'
}

.org section h1 {
    font-size: 1.3em !important
}

.organization-list {
    width: 100%;
    margin: 1em 0;
}

.organization-list input.search {
    border: 0;
    background: #fff;
    font: 1em 'Roboto', sans-serif;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
    width: 50%;
    margin: 0 0 0 1em !important;
    float: right;
    text-align: right;
    padding: 0 .5em;
}

.organization-list input.search:focus {
    outline: none
}

.organization-list th {
    font-weight: 700 !important;
    background: #f5f3f0;
    vertical-align: bottom
}

.organization-list th, .organization-list td {
    padding: .2em .5em;
}

.organization-list th:nth-child(2), .organization-list td:nth-child(2) {
    width: 20%;
    font-weight: 400
}

.organization-list th:nth-child(3), .organization-list td:nth-child(3) {
    text-align: right;
    width: 20%;
    font-weight: 400
}

.organization-list th:nth-child(4), .organization-list td:nth-child(4) {
    text-align: right
}

@media (max-width: 55em) {
    .organization-list th:nth-child(2), .organization-list td:nth-child(2), .organization-list th:nth-child(3), .organization-list td:nth-child(3), .organization-list th:nth-child(4), .organization-list td:nth-child(4) {
        display: none
    }
}

.organization-list .dialog {
    background: #4a0;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: .8em;
    white-space: nowrap;
    padding: 0 1em
}

.pagination {
    margin: 1em 0 0 0;
    padding: 1em;
    background: #eee;
    zoom: 1;
}

.pagination:before, .pagination:after {
    content: "";
    display: table
}

.pagination:after {
    clear: both
}

.pagination a {
    -webkit-border-radius: .4em;
    border-radius: .4em;
    background: -webkit-linear-gradient(top, #eHar inte tänkt på detd1c24, #de1219);
    background: -moz-linear-gradient(top, #ed1c24, #de1219);
    background: -o-linear-gradient(top, #ed1c24, #de1219);
    background: -ms-linear-gradient(top, #ed1c24, #de1219);
    background: linear-gradient(to bottom, #ed1c24, #de1219);
    text-decoration: none;
    color: #fff;
    padding: .2em 1em;
    display: inline-block;
    font-weight: 700;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    -webkit-transition: box-shadow 0.1s ease, text-shadow 0.2s ease;
    -moz-transition: box-shadow 0.1s ease, text-shadow 0.2s ease;
    -o-transition: box-shadow 0.1s ease, text-shadow 0.2s ease;
    -ms-transition: box-shadow 0.1s ease, text-shadow 0.2s ease;
    transition: box-shadow 0.1s ease, text-shadow 0.2s ease;
    -webkit-box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
    position: relative;
}

.pagination a:hover {
    -webkit-box-shadow: inset 0 10px 20px #f47b7f;
    box-shadow: inset 0 10px 20px #f47b7f;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.7)
}

.pagination a:active {
    bottom: -2px;
    background: -webkit-linear-gradient(top, #d41118, #ed1c24);
    background: -moz-linear-gradient(top, #d41118, #ed1c24);
    background: -o-linear-gradient(top, #d41118, #ed1c24);
    background: -ms-linear-gradient(top, #d41118, #ed1c24);
    background: linear-gradient(to bottom, #d41118, #ed1c24);
    -webkit-box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.15)
}

.pagination a.newer {
    float: left
}

.pagination a.older {
    float: right
}

.plugin_picture.align-right {
    margin: 1em 0 1em 1em;
    float: right
}

.fee-calculator {
    background: #e0dad2;
    -webkit-border-radius: .4em;
    border-radius: .4em;
    padding: 1em;
    margin: 1em 0;
}

.fee-calculator .group {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.fee-calculator .group:not(:last-child) {
    margin: 0 0 .5em 0
}

.fee-calculator label, .fee-calculator input, .fee-calculator .calculated-fee {
    height: 2em;
    line-height: 2em
}

.fee-calculator label {
    font-size: 1.2em;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    width: 0;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis
}

.fee-calculator input {
    font: 1.2em 'Roboto', sans-serif;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    width: 0;
    -webkit-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.4);
    padding: 0 .6em;
    border: 0;
    -webkit-border-radius: .2em;
    border-radius: .2em;
    text-align: right;
}

.fee-calculator input:focus {
    outline: none
}

.fee-calculator .group.fee {
    font-size: 1.2em;
    font-weight: 600;
    border-top: 1px solid #89775d;
    margin-top: 1em
}

.fee-calculator .calculated-fee {
    font-size: 1.2em;
    padding: 0 .5em
}

.newsletter-signup .group {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.newsletter-signup .group:not(:last-child) {
    margin: 0 0 .5em 0
}

.newsletter-signup label, .newsletter-signup input {
    height: 2em;
    line-height: 2em
}

.newsletter-signup label {
    cursor: pointer;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 8em;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.newsletter-signup label::after {
    content: ':'
}

.newsletter-signup input:not([type]), .newsletter-signup input[type='text'] {
    font: 1em 'Roboto', sans-serif;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    border: 1px solid #ddd;
    padding: 0 .6em;
    -webkit-border-radius: .2em;
    border-radius: .2em;
}

.newsletter-signup input:not([type]):focus, .newsletter-signup input[type='text']:focus {
    outline: none;
    border-color: #aaa
}

.newsletter-signup input[type='submit'] {
    font: 1em 'Roboto', sans-serif;
    height: auto;
    margin-left: 8em;
    padding: .5em 2em;
    display: inline-block;
    border: 1px solid #ccc;
    -webkit-border-radius: .2em;
    border-radius: .2em;
    background: -webkit-linear-gradient(top, #eee, #ddd);
    background: -moz-linear-gradient(top, #eee, #ddd);
    background: -o-linear-gradient(top, #eee, #ddd);
    background: -ms-linear-gradient(top, #eee, #ddd);
    background: linear-gradient(to bottom, #eee, #ddd);
    cursor: pointer;
}

.newsletter-signup input[type='submit']:hover, .newsletter-signup input[type='submit']:focus {
    outline: none;
    border-color: #aaa
}

.newsletter-signup input[type='submit']:active {
    outline: none;
    background: -webkit-linear-gradient(top, #bbb, #ccc);
    background: -moz-linear-gradient(top, #bbb, #ccc);
    background: -o-linear-gradient(top, #bbb, #ccc);
    background: -ms-linear-gradient(top, #bbb, #ccc);
    background: linear-gradient(to bottom, #bbb, #ccc)
}
