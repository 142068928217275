.frontpage nav#info-links {
    margin: 0 .5em;

    &::before {
        display: block;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -o-box-flex: 1;
        box-flex: 1;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        height: 1px;
        background: #ccc2b3;
        content: '';
        margin: 1em 0;
    }

    ul {
        display: -webkit-box;
        display: -moz-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    li {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -o-box-flex: 1;
        box-flex: 1;
        -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        width: 0;
        margin: 0 .25em;
        list-style: none;

        &:first-child {
            margin-left: 0;

            a {
                border-bottom-left-radius: 0.4em;
            }
        }

        &:last-child {
            margin-right: 0;

            a {
                border-bottom-right-radius: 0.4em;
            }
        }

        a {
            display: block;
            background: #fff;
            line-height: 1.1;
            padding: 1em;
            display: -webkit-box;
            display: -moz-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            -o-box-orient: vertical;
            -webkit-box-lines: single;
            -moz-box-lines: single;
            -o-box-lines: single;
            -webkit-flex-flow: column nowrap;
            -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
            -webkit-box-pack: center;
            -moz-box-pack: center;
            -o-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            text-decoration: none;
            color: #2e281f;
            -webkit-box-shadow: 0 3px 5px rgba(46, 40, 31, 0.08);
            box-shadow: 0 3px 5px rgba(46, 40, 31, 0.08);
            text-align: center;
            font-weight: 700;
            font-size: 1.1em;
            height: 6em;

            &::before {
                display: block;
                content: '';
                color: #aea79e;
                font: normal 3.2em FontAwesome;
                -webkit-transition: all 0.1s ease-out;
                -moz-transition: all 0.1s ease-out;
                -o-transition: all 0.1s ease-out;
                -ms-transition: all 0.1s ease-out;
                transition: all 0.1s ease-out;
                margin: 0 0 0.25em 0;
            }

            &.button--om-oss::before {
                content: "";
            }

            &.button--presse::before {
                content: "";
            }

            &.button--godkjente-organisasjoner::before {
                content: "";
            }

            &.button--nyhetsbrev::before {
                content: "";
            }

            &.button--kontakt-oss::before {
                content: "";
            }

            &.button--artikler::before {
                content: "";
            }

            &.button--bli-godkjent::before {
                content: "";
            }

            &.button--for-givere::before {
                content: "";
            }

            &.button--tips-oss::before {
                content: "";
            }

            &:hover::before {
                color: #2e281f;
                -webkit-transform: scale(1.2);
                -moz-transform: scale(1.2);
                -o-transform: scale(1.2);
                -ms-transform: scale(1.2);
                transform: scale(1.2);
            }
        }
    }
}

@media (max-width: 55em) {
    .frontpage nav#info-links {
        margin: 0;
    }
}

@media (max-width: 55em) {
    .frontpage nav#info-links ul {
        margin: .5em 0 0 .5em;
        -webkit-box-orient: horizontal;
        -moz-box-orient: horizontal;
        -o-box-orient: horizontal;
        -webkit-box-lines: multiple;
        -moz-box-lines: multiple;
        -o-box-lines: multiple;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }
}

@media (max-width: 55em) {
    .frontpage nav#info-links li {
        width: 40%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0 0.5em 0.5em 0 !important;

        a {
            height: 4em !important;

            &::before {
                font-size: 2em !important;
            }
        }
    }
}

@media (max-width: 40em) {
    .frontpage nav#info-links li a {
        font-size: 1em;
        -webkit-border-radius: 0 !important;
        border-radius: 0 !important;
    }
}